/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import { useDevice, useWindowDimensions } from "~hooks";
import Grid from "~styles/Grid.jsx";
import { Button, Go, Image, InfoCard, LineSwipe } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as ChevronRight } from "~assets/svg/chevron-right.svg";
import { ReactComponent as Wave6A } from "~assets/svg/header-6-shape-a.svg";

const StepsSection = ({ data: { subheading, heading, body, steps } }) => {
  const { isDesktop } = useDevice();
  const { windowSize } = useWindowDimensions();

  //

  return (
    <div
      id={subheading.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          //
        `,
        tw`relative bg-grey`
      ]}
    >
      <div css={[tw`relative pt-20 md:pt-24 lg:pt-32 md:pb-20 lg:pb-24`]}>
        <Grid>
          <header
            css={[
              tw`col-span-12 relative flex items-center mb-12 md:mb-16 lg:mb-24`
            ]}
          >
            <T.Head font="4">{subheading}</T.Head>

            <div css={[tw`w-32 relative block ml-5`]}>
              <LineSwipe color="#84bbd9" delay={500} duration={2000} />
            </div>
          </header>

          <div css={[tw`col-span-12 md:col-span-5 relative font-bold`]}>
            <T.Head font="2">{heading}</T.Head>
          </div>

          <div
            css={[
              tw`col-span-12 md:col-span-4 md:col-start-7 relative mt-10 md:mt-0 whitespace-pre-wrap text-black`
            ]}
          >
            <T.Body font="2">{body}</T.Body>
          </div>
        </Grid>
      </div>

      {/* step arrows */}

      {steps?.[0] && (
        <>
          <div css={[tw`relative hidden md:block`]}>
            <Grid>
              <div css={[tw`col-span-12 relative flex`]}>
                {steps.map((step, stepIndex) => {
                  return (
                    <div
                      css={[
                        css`
                          width: 100%;

                          ${MEDIA_QUERIES.desktop} {
                            width: calc(100% / ${steps.length});
                            flex-basis: calc(100% / ${steps.length});
                          }
                        `,
                        tw`relative pt-3 pr-8 md:pb-24 pl-3 border-l`
                      ]}
                    >
                      <p
                        css={[tw`mb-4 font-head text-button text-blue-bright`]}
                      >
                        Step {stepIndex + 1}
                      </p>

                      <T.Body _css={[tw`font-bold`]} font="1">
                        {step.heading}
                      </T.Body>

                      <a href={`#step-${stepIndex}`}>
                        <div
                          css={[
                            css`
                              bottom: -1.5rem;
                              left: -1.5rem;
                              background-color: ${getColor(`blue-white`)};
                              transition: 0.3s ease transform;
                              fill: ${getColor(`black`)};
                              border: 1px solid black;

                              ${MEDIA_QUERIES?.hoverable} {
                                &:hover {
                                  background-color: ${getColor(`blue-turq`)};
                                  transform: rotate(90deg);
                                  fill: ${getColor(`white`)};
                                  border: 0px;
                                }
                              }
                            `,
                            tw`w-12 h-12 absolute z-10 rounded-full`
                          ]}
                        >
                          <div
                            css={[
                              tw`w-full h-full relative flex items-center justify-center`
                            ]}
                          >
                            <ChevronRight css={[tw`w-2`]} />
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </div>

          {/* step content */}

          {steps.map((step, stepIndex) => {
            let paddingBottom = `8rem`;

            if (step?.contentImage) {
              const { gatsbyImageData } = step.contentImage.asset;
              const aspectRatio =
                gatsbyImageData?.width / gatsbyImageData?.height;

              paddingBottom = `${windowSize?.width * 0.33 * aspectRatio}px`;
            }

            return (
              <section
                id={`step-${stepIndex}`}
                key={`step-${step.heading}`}
                css={[
                  css`
                    ${MEDIA_QUERIES.desktop} {
                      padding-bottom: ${paddingBottom};
                    }
                  `,
                  tw`relative overflow-hidden pb-20 md:pt-16 md:pt-0`,
                  stepIndex === 0 && isDesktop() && tw`border-t`
                ]}
              >
                {step?.withWave && (
                  <div
                    css={[
                      tw`w-full h-full absolute top-0 right-0 bottom-0 left-0`
                    ]}
                  >
                    <Wave6A />
                  </div>
                )}

                {step?.sidebarImage && (
                  <figure
                    css={[
                      css`
                        display: none;

                        ${MEDIA_QUERIES.desktop} {
                          width: 30%;
                          max-width: 460px;
                          display: block;
                        }
                      `,
                      tw`absolute bottom-0 left-0`
                    ]}
                  >
                    <Image image={step.sidebarImage} />
                  </figure>
                )}

                {step?.contentImage && (
                  <figure
                    css={[
                      css`
                        display: none;

                        ${MEDIA_QUERIES.desktop} {
                          width: 40%;
                          display: block;
                        }
                      `,
                      tw`absolute bottom-0 right-0`
                    ]}
                  >
                    <Image
                      _css={[tw`w-full relative block`]}
                      image={step.contentImage}
                    />
                  </figure>
                )}

                <Grid>
                  <span
                    css={[tw`col-span-12`, stepIndex !== 0 && tw`border-t`]}
                  />

                  <header
                    css={[
                      tw`col-span-12 relative flex items-center mb-10 pt-12 md:pt-16 lg:pt-24`
                    ]}
                  >
                    <T.Head font="4">Step {stepIndex + 1}</T.Head>

                    <div css={[tw`w-32 relative block ml-5`]}>
                      <LineSwipe color="#84bbd9" delay={500} duration={2000} />
                    </div>
                  </header>

                  <div css={[tw`col-span-12 md:col-span-3 relative font-bold`]}>
                    <T.Head _css={[tw`mb-10 md:mb-0`]} font="2">
                      {step.heading}
                    </T.Head>

                    {step?.sidebarButton?.text && step?.sidebarButton?.url && (
                      <Go
                        to={step?.sidebarButton?.url}
                        css={[
                          css`
                            display: none;

                            ${MEDIA_QUERIES.desktop} {
                              display: block;
                            }
                          `
                        ]}
                      >
                        <Button
                          _css={[tw`h-16 md:mt-16 lg:mt-20 border`]}
                          text={step?.sidebarButton?.text}
                        />
                      </Go>
                    )}
                  </div>

                  <div
                    css={[
                      tw`col-span-12 md:col-span-6 md:col-start-6 relative whitespace-pre-wrap text-black`
                    ]}
                  >
                    <div css={[tw`relative z-10`]}>
                      <T.Body font="2">{step?.body}</T.Body>

                      {step?.cards?.[0] && (
                        <ul
                          css={[
                            tw`w-full relative mt-12 gap-4 md:flex items-stretch`
                          ]}
                        >
                          {step.cards.map((card) => (
                            <li
                              key={`step-card-${card.heading}`}
                              css={[
                                css`
                                  flex: 0 1 auto;
                                `,
                                tw`w-full md:w-1/2 relative block mb-8 md:mb-0`
                              ]}
                            >
                              <InfoCard
                                _css={[tw`h-full`]}
                                backgroundColor={card.backgroundColor}
                                headingFontColor={card.headingFontColor}
                                fontColor={card.fontColor}
                                heading={card.heading}
                                subsections={card.subsections}
                              />
                            </li>
                          ))}
                        </ul>
                      )}

                      {(step?.footnoteHeading || step?.footnoteBody) && (
                        <div
                          css={[
                            tw`w-full relative block mt-16 whitespace-pre-wrap`
                          ]}
                        >
                          {step?.footnoteHeading && (
                            <T.Body
                              _css={[
                                css`
                                  font-size: 24px !important;
                                `,
                                tw`mb-4 font-bold`
                              ]}
                              font="1"
                            >
                              {step.footnoteHeading}
                            </T.Body>
                          )}

                          {step?.footnoteBody && (
                            <T.Body font="1">{step.footnoteBody}</T.Body>
                          )}
                        </div>
                      )}

                      {(step?.linkListLeft?.[0] ||
                        step?.linkListMiddle?.[0] ||
                        step?.linkListRight?.[0]) && (
                        <div css={[tw`w-full relative md:flex mt-16 gap-4`]}>
                          <div
                            css={[
                              tw`w-full md:w-1/3 relative block mt-12 md:mt-0`
                            ]}
                          >
                            {step?.linkHeadingLeft && (
                              <T.Body _css={[tw`mb-4 font-bold`]} font="1">
                                {step.linkHeadingLeft}
                              </T.Body>
                            )}

                            {step?.linkListLeft?.[0] && (
                              <ul>
                                {step.linkListLeft.map((listItem) => (
                                  <li key={`left-list-item-${listItem?.text}`}>
                                    <Go to={listItem.url}>
                                      <T.Body
                                        _css={[tw`underline font-light`]}
                                        font="2"
                                      >
                                        {listItem.text}
                                      </T.Body>
                                    </Go>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>

                          <div
                            css={[
                              tw`w-full md:w-1/3 relative block mt-12 md:mt-0`
                            ]}
                          >
                            {step?.linkHeadingMiddle && (
                              <T.Body _css={[tw`mb-4 font-bold`]} font="1">
                                {step.linkHeadingMiddle}
                              </T.Body>
                            )}

                            {step?.linkListMiddle?.[0] && (
                              <ul>
                                {step.linkListMiddle.map((listItem) => (
                                  <li
                                    key={`middle-list-item-${listItem?.text}`}
                                  >
                                    <Go to={listItem.url}>
                                      <T.Body
                                        _css={[tw`underline font-light`]}
                                        font="2"
                                      >
                                        {listItem.text}
                                      </T.Body>
                                    </Go>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>

                          <div
                            css={[
                              tw`w-full md:w-1/3 relative block mt-12 md:mt-0`
                            ]}
                          >
                            {step?.linkHeadingRight && (
                              <T.Body _css={[tw`mb-4 font-bold`]} font="1">
                                {step.linkHeadingRight}
                              </T.Body>
                            )}

                            {step?.linkListRight?.[0] && (
                              <ul>
                                {step.linkListRight.map((listItem) => (
                                  <li key={`right-list-item-${listItem?.text}`}>
                                    <Go to={listItem.url}>
                                      <T.Body
                                        _css={[tw`underline font-light`]}
                                        font="2"
                                      >
                                        {listItem.text}
                                      </T.Body>
                                    </Go>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      )}

                      {isDesktop() &&
                        step?.contentButton?.text &&
                        step?.contentButton?.url && (
                          <Go to={step?.contentButton?.url}>
                            <Button
                              _css={[tw`h-16 mt-16 border`]}
                              text={step?.contentButton?.text}
                            />
                          </Go>
                        )}
                    </div>

                    {!isDesktop() && (
                      <div css={[tw`mt-12`]}>
                        {step?.sidebarButton?.text && step?.sidebarButton?.url && (
                          <Go to={step?.sidebarButton?.url}>
                            <Button
                              _css={[tw`h-16 md:mt-16 lg:mt-20 border`]}
                              text={step?.sidebarButton?.text}
                            />
                          </Go>
                        )}

                        {step?.contentButton?.text && step?.contentButton?.url && (
                          <Go to={step?.contentButton?.url}>
                            <Button
                              _css={[tw`h-16 mt-16 border`]}
                              text={step?.contentButton?.text}
                            />
                          </Go>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </section>
            );
          })}
        </>
      )}
    </div>
  );
};

export default StepsSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment StepsSectionFragment on SanityStepsSection {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    subheading
    heading
    body

    steps {
      withWave
      heading
      body

      contentImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
        }
      }

      contentButton {
        text
        url
      }

      sidebarImage {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
        }
      }

      sidebarButton {
        text
        url
      }

      footnoteHeading
      footnoteBody

      cards {
        backgroundColor {
          hex
          title
        }

        headingFontColor {
          hex
          title
        }

        fontColor {
          hex
          title
        }

        heading

        subsections {
          _type
          body
          heading
        }
      }

      linkHeadingLeft
      linkListLeft {
        text
        url
      }

      linkHeadingMiddle
      linkListMiddle {
        text
        url
      }

      linkHeadingRight
      linkListRight {
        text
        url
      }
    }
  }
`;
